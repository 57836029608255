// src/pages/Veiculos.js
import React, { useEffect, useState } from "react";
import { fetchVehicles, fetchCarouselImages } from "../services/api"; // Importação do fetchCarouselImages
import { Card, CardContent, Typography, Container, Grid, Button, CardMedia } from "@mui/material";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Veiculos = () => {
  const [vehicles, setVehicles] = useState([]);
  const [carouselImages, setCarouselImages] = useState([]); // Estado para imagens do carrossel principal

  useEffect(() => {
    fetchVehicles()
      .then(data => setVehicles(data))
      .catch(error => console.error("Erro ao buscar veículos:", error));

    fetchCarouselImages() // Busca imagens do carrossel do painel
      .then(images => setCarouselImages(images))
      .catch(error => console.error("Erro ao buscar imagens do carrossel:", error));
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Veículos Disponíveis
      </Typography>

      {/* Carrossel de Imagens Principal */}
      <Slider {...settings}>
        {carouselImages.length > 0 ? (
          carouselImages.map((image, index) => (
            <div key={index}>
              <img
                src={image} // URL completa da imagem no carrossel
                alt={`Slide ${index + 1}`}
                style={{ width: "100%", height: "400px", objectFit: "cover" }}
              />
            </div>
          ))
        ) : (
          <Typography variant="body2" align="center">
            Nenhuma imagem disponível para o carrossel.
          </Typography>
        )}
      </Slider>

      {/* Listagem dos Veículos */}
      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        {vehicles.map(vehicle => (
          <Grid item xs={12} sm={6} md={4} key={vehicle.id}>
            <Card>
              {/* Adiciona a imagem de destaque do veículo com altura ajustada */}
              {vehicle.imagem_destaque && (
                <CardMedia
                  component="img"
                  height="200" // Altura ajustada da imagem
                  image={vehicle.imagem_destaque}
                  alt={`Imagem de ${vehicle.modelo}`}
                />
              )}
              <CardContent>
                <Typography variant="h5" component="div">
                  {vehicle.modelo}
                </Typography>
                <Typography color="text.secondary">
                  Fabricante: {vehicle.fabricante}
                </Typography>
                <Typography color="text.secondary">
                  Ano: {vehicle.anoFabricacao} / {vehicle.anoModelo}
                </Typography>
                <Typography color="text.secondary">
                  Quilometragem: {vehicle.quilometragem} km
                </Typography>
                <Typography color="text.primary" variant="h6">
                  Valor: R$ {parseFloat(vehicle.valor_pedido).toLocaleString()}
                </Typography>
                <Typography variant="body2">
                  {vehicle.descricao_curta}
                </Typography>
                {/* Botão para página de detalhes */}
                <Button 
                  variant="contained" 
                  color="primary" 
                  style={{ marginTop: '10px' }}
                  component={Link}
                  to={`/veiculo/${vehicle.id}`} // Link para a página de detalhes
                >
                  Ver Detalhes
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Veiculos;
