// src/pages/VehicleDetails.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Container, Typography, Box, Grid, TextField, Button, MenuItem } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const VehicleDetails = () => {
  const { id } = useParams();
  const [vehicle, setVehicle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Campos do formulário de financiamento
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [installments, setInstallments] = useState("");
  const [cpf, setCpf] = useState("");

  const installmentOptions = ["12", "24", "36", "48", "60"];

  useEffect(() => {
    const fetchVehicleDetails = async () => {
      try {
        const response = await axios.get(`https://api.velhochicoveiculos.com.br/api/public/vehicles/${id}`);
        setVehicle(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar detalhes do veículo:", error);
        setError("Erro ao carregar detalhes do veículo.");
        setLoading(false);
      }
    };

    fetchVehicleDetails();
  }, [id]);

  const generateBiography = (vehicle) => `
    ${vehicle.fabricante} ${vehicle.modelo}
    Ano/Mod: ${vehicle.anoFabricacao}/${vehicle.anoModelo}
    Completo

    Km: ${vehicle.quilometragem}

    ———————————————

    ✅ Direção hidráulica

    IPVA E LICENCIAMENTO GRÁTIS.

    🔘 Financiamento em até 60x *
    🔘 Taxas mensais a partir de 1.20% *
    🔘 Até 45 dias para começar a pagar *
    *Consulte condições.
  `;

  const handleCpfChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length > 11) value = value.slice(0, 11);
    const formattedCpf = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    setCpf(formattedCpf);
  };

  const handleWhatsappChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length > 11) value = value.slice(0, 11);
    const formattedWhatsapp = value.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    setWhatsapp(formattedWhatsapp);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!id) {
      alert("ID do veículo não encontrado. Por favor, tente novamente.");
      return;
    }
    try {
      await axios.post("https://api.velhochicoveiculos.com.br/api/finance/request", {
        vehicle_id: id,
        name,
        email,
        whatsapp,
        down_payment: downPayment,
        installments,
        cpf,
      });
      alert("Solicitação de financiamento enviada com sucesso!");
      setName("");
      setEmail("");
      setWhatsapp("");
      setDownPayment("");
      setInstallments("");
      setCpf("");
    } catch (error) {
      console.error("Erro ao enviar solicitação de financiamento:", error);
      alert("Erro ao enviar solicitação de financiamento.");
    }
  };

  if (loading) return <Typography>Carregando...</Typography>;
  if (error) return <Typography>{error}</Typography>;

  // Configuração do slideshow
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Container>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          {vehicle.modelo} - Detalhes
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {vehicle.fotos && vehicle.fotos.length > 0 ? (
              <Slider {...settings}>
                {vehicle.fotos.map((foto, index) => (
                  <Box key={index} component="img" src={foto} alt={`${vehicle.modelo} - foto ${index + 1}`}
                    sx={{ width: "100%", height: "auto", objectFit: "cover", maxHeight: 400 }}
                  />
                ))}
              </Slider>
            ) : (
              <Typography>Sem imagens disponíveis para este veículo.</Typography>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6">Fabricante: {vehicle.fabricante}</Typography>
            <Typography variant="h6">Ano: {vehicle.anoFabricacao} / {vehicle.anoModelo}</Typography>
            <Typography variant="h6">Quilometragem: {vehicle.quilometragem} km</Typography>
            <Typography variant="h6">Valor: R$ {parseFloat(vehicle.valor_pedido).toLocaleString()}</Typography>
            <Box sx={{ mt: 4 }}>
              <Typography variant="h5">Biografia do Veículo</Typography>
              <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                {generateBiography(vehicle)}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Formulário de Financiamento */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom>Solicitar Financiamento</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Nome"
              variant="outlined"
              fullWidth
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="CPF"
              variant="outlined"
              fullWidth
              required
              value={cpf}
              onChange={handleCpfChange}
              placeholder="000.000.000-00"
              sx={{ mb: 2 }}
            />
            <TextField
              label="Endereço de Email"
              variant="outlined"
              fullWidth
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Número WhatsApp"
              variant="outlined"
              fullWidth
              required
              value={whatsapp}
              onChange={handleWhatsappChange}
              placeholder="(00) 00000-0000"
              sx={{ mb: 2 }}
            />
            <TextField
              label="Valor de Entrada"
              variant="outlined"
              fullWidth
              required
              value={downPayment}
              onChange={(e) => setDownPayment(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              select
              label="Parcelas"
              variant="outlined"
              fullWidth
              required
              value={installments}
              onChange={(e) => setInstallments(e.target.value)}
              sx={{ mb: 2 }}
            >
              {installmentOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}x
                </MenuItem>
              ))}
            </TextField>
            <Button type="submit" variant="contained" color="primary">
              Enviar Solicitação
            </Button>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export default VehicleDetails;
