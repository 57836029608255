// src/services/api.js

const API_URL = "https://api.velhochicoveiculos.com.br/api/public";
const ADMIN_API_URL = "https://api.velhochicoveiculos.com.br/api/admin"; // URL para operações administrativas

// Função para buscar a lista de veículos
export async function fetchVehicles() {
  const response = await fetch(`${API_URL}/vehicles`);
  if (!response.ok) {
    throw new Error("Erro ao buscar veículos");
  }
  return response.json();
}

// Função para buscar as imagens do carrossel
export async function fetchCarouselImages() {
  const response = await fetch(`${API_URL}/carousel-images`);
  if (!response.ok) {
    throw new Error("Erro ao buscar imagens do carrossel");
  }
  return response.json();
}

// Função para adicionar uma nova imagem ao carrossel
export async function addCarouselImage(url) {
  const response = await fetch(`${ADMIN_API_URL}/carousel-images`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ url }),
  });
  if (!response.ok) {
    throw new Error("Erro ao adicionar imagem ao carrossel");
  }
  return response.json();
}

// Função para atualizar uma imagem do carrossel
export async function updateCarouselImage(id, url) {
  const response = await fetch(`${ADMIN_API_URL}/carousel-images/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ url }),
  });
  if (!response.ok) {
    throw new Error("Erro ao atualizar imagem do carrossel");
  }
  return response.json();
}

// Função para deletar uma imagem do carrossel
export async function deleteCarouselImage(id) {
  const response = await fetch(`${ADMIN_API_URL}/carousel-images/${id}`, {
    method: "DELETE",
  });
  if (!response.ok) {
    throw new Error("Erro ao deletar imagem do carrossel");
  }
  return response.json();
}
