// src/components/Header.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, IconButton, Typography, Button, Drawer, List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"; // Ícone para "Vender ou Trocar"
import "./Header.css"; // Importa o CSS do Header

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawerOpen(open);
  };

  const navLinks = [
    { title: "Home", path: "/", icon: <HomeIcon /> },
    { title: "Contato", path: "/contato", icon: <ContactMailIcon /> },
    { title: "Vender ou Trocar", path: "/sell-or-trade", icon: <SwapHorizIcon /> },
  ];

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: "#000", minHeight: "70px" }}> {/* Fundo forçado para preto */}
        <Toolbar className="toolbar">
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)} className="menu-icon">
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" component="div" className="logo">
            Loja de Veículos
          </Typography>

          <div className="desktop-links">
            {navLinks.map((link) => (
              <Button
                color="inherit"
                component={Link}
                to={link.path}
                key={link.title}
                startIcon={link.icon} // Adiciona o ícone ao botão
                className="nav-button"
              >
                {link.title}
              </Button>
            ))}
          </div>
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)} className="drawer">
        <List className="drawer-list">
          {navLinks.map((link) => (
            <ListItem
              button
              component={Link}
              to={link.path}
              key={link.title}
              onClick={toggleDrawer(false)}
              className="drawer-list-item"
            >
              <ListItemIcon>{link.icon}</ListItemIcon>
              <ListItemText primary={link.title} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default Header;
