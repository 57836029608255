// src/App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Veiculos from "./pages/Veiculos";
import VehicleDetails from "./pages/VehicleDetails";
import SellOrTradeVehicle from "./pages/SellOrTradeVehicle";

function App() {
  return (
    <Router>
      <Header /> {/* Usa o Header importado */}

      <main>
        <Routes>
          <Route path="/" element={<Veiculos />} />
          <Route path="/veiculo/:id" element={<VehicleDetails />} />
          <Route path="/sell-or-trade" element={<SellOrTradeVehicle />} />
        </Routes>
      </main>
    </Router>
  );
}

export default App;
