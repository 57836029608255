// src/pages/SellOrTradeVehicle.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, TextField, Button, Checkbox, FormControlLabel, MenuItem } from '@mui/material';

const SellOrTradeVehicle = () => {
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [intentions, setIntentions] = useState([]);
  const [requestedPrice, setRequestedPrice] = useState('');
  const [auction, setAuction] = useState(false);
  const [hasFines, setHasFines] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [error, setError] = useState(null);
  const [vehicles, setVehicles] = useState([]);

  // Buscar veículos em estoque para a opção de troca
  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const response = await axios.get('https://api.velhochicoveiculos.com.br/api/public/vehicles');
        setVehicles(response.data);
      } catch (error) {
        console.error('Erro ao buscar veículos:', error);
        setError('Erro ao carregar veículos');
      }
    };
    fetchVehicles();
  }, []);

  const handleFileChange = (e) => {
    setPhotos(e.target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('cpf', cpf);
    formData.append('intentions', JSON.stringify(intentions));
    formData.append('requested_price', requestedPrice);
    formData.append('auction', auction ? 1 : 0);
    formData.append('has_fines', hasFines ? 1 : 0);

    Array.from(photos).forEach((file) => formData.append('photos', file));

    try {
      await axios.post('https://api.velhochicoveiculos.com.br/api/public/vehicle-upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      alert('Solicitação enviada com sucesso!');
    } catch (error) {
      console.error('Erro ao enviar a solicitação:', error);
      setError('Erro ao enviar a solicitação');
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Venda ou Troca de Veículo</Typography>
      {error && <Typography color="error">{error}</Typography>}
      <form onSubmit={handleSubmit}>
        <TextField label="Qual seu nome?" fullWidth value={name} onChange={(e) => setName(e.target.value)} required />
        <TextField label="Qual seu CPF?" fullWidth value={cpf} onChange={(e) => setCpf(e.target.value)} required />
        <FormControlLabel
          control={
            <Checkbox
              checked={intentions.includes('vender')}
              onChange={(e) => {
                const newIntentions = e.target.checked
                  ? [...intentions, 'vender']
                  : intentions.filter((intent) => intent !== 'vender');
                setIntentions(newIntentions);
              }}
            />
          }
          label="Vender"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={intentions.includes('trocar')}
              onChange={(e) => {
                const newIntentions = e.target.checked
                  ? [...intentions, 'trocar']
                  : intentions.filter((intent) => intent !== 'trocar');
                setIntentions(newIntentions);
              }}
            />
          }
          label="Trocar"
        />
        {intentions.includes('trocar') && (
          <TextField
            select
            label="Escolha um veículo para troca"
            fullWidth
            SelectProps={{ multiple: true }}
            onChange={(e) => setIntentions(e.target.value)}
            value={intentions}
          >
            {vehicles.map((vehicle) => (
              <MenuItem key={vehicle.id} value={vehicle.id}>
                {vehicle.modelo}
              </MenuItem>
            ))}
          </TextField>
        )}
        <TextField
          label="Qual o valor pedido no seu carro?"
          fullWidth
          value={requestedPrice}
          onChange={(e) => setRequestedPrice(e.target.value)}
          required
        />
        <FormControlLabel
          control={<Checkbox checked={auction} onChange={(e) => setAuction(e.target.checked)} />}
          label="Seu carro é de Leilão?"
        />
        <FormControlLabel
          control={<Checkbox checked={hasFines} onChange={(e) => setHasFines(e.target.checked)} />}
          label="Seu carro possui multas?"
        />
        <input type="file" multiple onChange={handleFileChange} required />
        <Button type="submit" variant="contained" color="primary">Enviar Solicitação</Button>
      </form>
    </Container>
  );
};

export default SellOrTradeVehicle;
